@import '../../../../scss/settings';

.PasswordField {
    position: relative;

    input {
        padding-right: 60px;
    }

    .password-visibility-button {
        position: absolute;

        right: 0;
        top: 0;
        height: 100%;

        padding: 0 15px 2px 15px;

        border: 0;
        background: none;

        color: $form-secondary-text-color;
        font-size: 14px;
        text-decoration: underline;

        &:focus {
            outline: none;
            color: $brand-color;
        }
    }
}
