@import '../../../../scss/settings';

.RegisterForm {
    padding: 20px;

    // Used to avoid autocompleting fields (because autocomplete will fill in the password which can be shown)
    .fake-password {
        display: inline;
        position: absolute;
        top: -10px;
        left: -10px;
        width: 0px;
        max-height: 0px;
        visibility: hidden;
    }

    .submit {
        width: 100%;
        margin: 24px 0;

        &:disabled {
            background: $mid-grey;
        }
    }

    .disclaimer {
        margin: 23px 10% 0 10%;

        text-align: center;
        font-size: 14px;
        color: $mid-grey;

        a {
            color: $mid-grey;
        }
    }

    .secondary {
        color: $mid-light-grey;
    }

    @media (max-width: $break_mobile) {
        padding: 20px 0;
    }
}
