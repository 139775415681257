@import '../../../../scss/settings';

.Field {
    input {
        width: 100%;
        padding: 9px 15px 11px 15px;

        border: 1px solid $form-input-border-color;
        border-radius: 3px;

        background: $form-input-background-color;

        color: $form-input-text-color;
        font-size: 16px;

        &::-webkit-input-placeholder {
            color: $form-input-placeholder-color;
        }

        &:-ms-input-placeholder {
            color: $form-input-placeholder-color;
        }

        &::-moz-placeholder {
            color: $form-input-placeholder-color;
        }

        &:focus {
            border-color: $form-input-focus-border-color;
            outline: none;
        }

        &:disabled {
            border-color: $light-grey;
            color: $mid-grey;
        }
    }

    &.error input {
        border-color: $form-error-color;
    }
}
