@import '../../../../../scss/settings';

.Button.SocialSignInButton {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    padding: 0;
    margin-bottom: 0.8rem;

    border: 1px solid #d1d3d6;
    border-radius: 3px;

    outline: none;
    background: $white;

    color: $white;
    font-family: $font-body;
    font-size: 16px;
    font-weight: $font-weight-semibold;

    cursor: pointer;

    .logo-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Logo {
        width: 36px;
        height: 36px;
    }

    .text {
        color: $black;
        text-align: center;
    }

    .AppleLogo {
        width: 36px;
        height: 36px;
    }

    .GoogleLogo {
        border-radius: 2px;
        width: 36px;
        height: 36px;
    }

    &:last-of-type {
        margin: 0;
    }
}
