@import '../../../../scss/settings';

.SubscriptionForm {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .rss-json-description {
        font-family: $font-blog-body;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.56;
        text-align: center;
        color: #b2b2b2;

        a {
            color: #b2b2b2;
        }
    }

    .email-form {
        justify-content: center;
        margin-bottom: 30px;
    }

    .FormError {
        text-align: left;
        margin-top: 0;
    }

    .FormRow {
        margin: 0;
        justify-content: center;

        .field-info,
        .field-row {
            margin: 0;
        }

        .field-row {
            display: flex;
            align-items: stretch;
            margin-bottom: 10px;

            .header {
                margin-bottom: 0;

                .FieldError {
                    margin-top: 10px;
                }
            }
        }

        .field {
            margin-right: 10px;
        }
    }

    .TextField input {
        font-size: 16px;
        font-family: $font-blog-body;
        border-radius: 3px;
        background-color: $white;
        padding: 8px 16px;
        border: 1px solid $white;
        color: $modal-secondary-color;
        width: 280px;
        box-sizing: content-box;

        &:focus {
            border: 1px solid $form-input-focus-border-color;
        }
    }

    .submit {
        width: 100%;
        height: 100%;
        padding: 4px 40px 6px 40px;
        background: $blog-brand-color;

        &:disabled {
            background: $mid-grey;
        }
    }

    @media (max-width: $break_mobile) {
        flex-direction: column;

        .FormRow .field-row {
            flex-direction: column;

            .field {
                margin-right: 0;
            }
        }

        .submit {
            padding: 10px 15px 11px 15px;
        }

        .TextField input {
            padding: 10px 25px 10px 18px;
            width: 100%;
            margin-bottom: 20px;
            box-sizing: border-box;
        }
    }
}
