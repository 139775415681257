@import '../../../../scss/settings';

.FormRow {
    width: 100%;
    margin-bottom: 2.4rem;

    &:last-of-type {
        margin-bottom: 2.5rem;
    }

    .field-info {
        display: flex;
        align-items: baseline;
        justify-content: space-between;

        margin-bottom: 1.2rem;

        .field-message {
            margin-bottom: 0;
        }

        & + .field-message {
            margin-top: -1.2rem;
        }
    }

    .field-message {
        margin-bottom: 0.6rem;
        font-size: 14px;
        line-height: 1em;
        text-align: left;

        &.error {
            color: $form-error-color;
        }
    }

    .field-row {
        display: flex;
        align-items: center;

        .field {
            flex-grow: 1;
            margin-right: 2rem;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    .field-helper {
        margin-top: 0.6rem;
    }
}
